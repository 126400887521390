<template>
  <div class="region-div">
      <div class="top">
         <Menu theme="light" style="height:50px" mode="horizontal" :active-name="menuName" @on-select="selectMenu">
              <MenuItem name="grid">
                网格
              </MenuItem>
              <MenuItem name="build">
                楼组
              </MenuItem>
              <MenuItem name="hu">
                户
              </MenuItem>
         </Menu>
      </div>
      <div class="bottom">
          <div class="container" v-show="menuName == 'grid'">
            <Grid :code="orgCode" :data="tableData.gridIdList" @tableSelect="tableSelect"></Grid>
          </div>
          <div class="container" v-show="menuName == 'build'">
            <Build :code="orgCode" :data="tableData.regionGovernmentVos" @tableSelect="tableSelectBuild"></Build>
          </div>
          <div class="container" v-show="menuName == 'hu'">
            <Tree :code="orgCode" :data="tableData.houseGovernmentVos" @deleteRow="deleteRow" @handleTree="handleTree"></Tree>
          </div>
      </div>
  </div>
</template>

<script>
import Grid from '../form/grid.vue'
import Build from '../form/build.vue'
import Tree from '../form/tree'
export default {
    components:{Grid,Build,Tree},
    props:{
      userId:{
        type:Object,
        default:()=>{
          return ''
        }
      },
    },
    data(){
      return{
        menuName:'grid',
        orgCode:'',
        tableData:{},
        currenTitle:'',
        treeData:[],
        treeRegionData:[]
      }
    },
    methods:{
      // 选择了网格
      tableSelect(val){
        if(val.length != 0){
          this.tableData.gridIdList = val
        }else{
          this.tableData.gridIdList = []
        }
      },
      tableSelectBuild(val){
        if(val.length != 0){
          this.tableData.regionGovernmentVos = val
        }else{
          this.tableData.regionGovernmentVos = []
        }
      },
      // 点击了树节点的东西
        handleTree(val,treeData){
          console.log('val',val);
          console.log('treeData',treeData);
          this.treeData = treeData
          if(val && val.length != 0){
            val.map(item=>{
              this.currenTitle = ''
              this.getCurrentTitle(item.id,treeData)
              item.regionWholeAddr = this.currenTitle
              this.tableData.houseGovernmentVos.push(item)
            })
          }       
          this.tableData.houseGovernmentVos = Array.from(new Set(this.tableData.houseGovernmentVos))  
        },
           //递归树,获取名称
        getCurrentTitle(id, treeData) {
          for(let k in treeData) {
            if(treeData[k].id == id) {
              this.currenTitle = (treeData[k].name || treeData[k].title) + this.currenTitle;
              if(!treeData[k].parentId) return;
              this.getCurrentTitle(treeData[k].parentId,this.treeData)
            }else if(treeData[k].children && treeData[k].children.length > 0){
              this.getCurrentTitle(id,treeData[k].children)
            }
          }
        },
      selectMenu(name){
          this.menuName = name
        
      },
       getRegionCurrentTitle(id, treeData) {
          for(let k in treeData) {
            if(treeData[k].id == id) {
              this.currenTitle = (treeData[k].name || treeData[k].title) + this.currenTitle;
              if(!treeData[k].parentId) return;
              this.getRegionCurrentTitle(treeData[k].parentId,this.treeRegionData)
            }else if(treeData[k].children && treeData[k].children.length > 0){
              this.getRegionCurrentTitle(id,treeData[k].children)
            }
          }
        },
       handleRegionTree(val,treeData){
          console.log('val',val);
          console.log('treeData',treeData);
          this.treeRegionData = treeData
          if(val && val.length != 0){
            console.log(val);
            val.map(item=>{
              this.currenTitle = ''
              this.getRegionCurrentTitle(item.id,treeData)
              item.regionWholeAddr = this.currenTitle
              this.tableData.regionGovernmentVos.push(item)
            })
            this.tableData.regionGovernmentVos = Array.from(new Set(this.tableData.regionGovernmentVos))  
          }else{
            this.tableData.regionGovernmentVos = []
          }       
          
        },
      // 户数的删除
      deleteRegionRow(item){
        let arr = []
        this.tableData.regionGovernmentVos.map(columns=>{
          if(!item.houseNo) item.houseNo = item.code
          if(columns.houseNo != item.houseNo){
            arr.push(columns)
          }
        })
        this.tableData.regionGovernmentVos = arr
      

      },
      // 户数的删除
      deleteRow(item){
        let arr = []
        this.tableData.houseGovernmentVos.map(columns=>{
          if(!item.houseNo) item.houseNo = item.code
          if(columns.houseNo != item.houseNo){
            arr.push(columns)
          }
        })
        this.tableData.houseGovernmentVos = arr
      

      },
     async getList(){
       await this.$get('/syaa/pc/sy/user/communityStructure/selectStructureRole',{
          custGlobalId:this.userId.custGlobalId,
          orgCode:this.userId.orgCode
        }).then(res=>{
          if(res.code == 200){
            this.tableData = {}         
            if(res.data.houseGovernmentVos == null) res.data.houseGovernmentVos = []
            else res.data.houseGovernmentVos.map(item =>{
              item.code == item.houseNo
            })
            this.tableData = res.data
            if(res.data.regionGovernmentVos == null) this.tableData.regionGovernmentVos = res.data.regionIdList
          }else{
            this.$Message.error({
              content:res.desc,
              background:true
            })
            return
          }
        })
      }
    },
    async created(){
      this.orgCode = this.userId.orgCode
      await this.getList()
      
    },
}
</script>

<style lang="less" scoped>
.region-div{
  .top{
    height: 40px;
  }
  .bottom{
    margin-top: 20px;
    height: 400px;
    width: 100%;
    .container{
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      padding-left: 20px;
    }
  }
}
/deep/ .ivu-table{
  overflow-y: scroll !important;
}
/deep/ .ivu-table-overflowX{
  overflow-x:visible !important;
}
</style>